body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.content-block {
  min-height: 100vh;
}

.main {
  /* Calculated height */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-tertiary);
  background-color: white;
  box-shadow: none;
}

img {
  object-fit: cover;
  object-position: center;
}

ul li {
  list-style: none;
}

.section {
  margin-bottom: 120px !important;
}

footer {
  margin-top: 120px;
}

h1 {
  margin: 0 0 30px;
  font-size: 45px;
  font-weight: 900;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.22);
}

.index-body {
  font-family: 'Instrument Sans', sans-serif;
}

h2 {
  font-family: var(--bs-h2-font-family);
  font-size: var(--bs-h2-font-size);
  font-weight: var(--bs-font-weight-normal);
  color: var(--bs-primary);
  line-height: 44.8px;
  margin: 0;
}

.base-font {
  font-family: var(--bs-font-family);
}

.hero-heading-bg {
  background-image: url(/public/images/home-frame.png);
  background-repeat: no-repeat;
  background-position: top;

}

.hero-heading {
  margin-top: 200px;
}

.error {
  height: calc(100vh - 66px);
}

.hotels {
  height: calc(100vh - 75px);
}

.carousel-hero {
  height: calc(100vh - 300px);
}

.carousel-hero img,
.base-logo img,
.hero-sub-img,
.logo {
  height: 100%;
}

.base-service {
  background: #F6F6FC;
  /* background: linear-gradient(360deg, rgba(63, 81, 181, 0) 0%, rgba(179, 198, 255, 0.08) 88.19%); */

}

.ben-icon {
  color: #D1704A;
}

.hero-sub-img {
  max-width: 100%;
}

.hero-sub-img img {
  width: 100%;
  height: auto;
}

.header-above {
  width: 100%;
}

.title-part {
  margin-bottom: 40px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social {
  width: 40px;
  height: 40px;
}

.social-responsive {
  display: none;
}

.facility-part p {
  line-height: 27px;
}

.pointer-cursor {
  cursor: pointer;
}

.font-size-64 {
  font-size: 64px;
}

.font-size-56 {
  font-size: 56px;
}

.font-size-80 {
  font-size: 80px;
}

.font-size-40 {
  font-size: 40px;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-28 {
  font-size: 28px;
}

.line-height-21 {
  line-height: 21px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-27 {
  line-height: 27px;
}

.line-height-30 {
  line-height: 30px;
}

.line-height-52 {
  line-height: 52px;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.tagline-desc {
  max-width: 600px;
}

.green-color {
  color: #198754;
}

.text-green {
  color: rgba(var(--bs-green-rgb), 0.6) !important;
}

.text-danger {
  color: red;
}

.light-green-color {
  color: #044e75cc;
}

.col-heading {
  width: auto;
  /* Width for the first column */
}

.col-content {
  width: 150px;
  /* Width for all other columns */
}

/* .navbar-nav .nav-item .active {
  color: var(--bs-li-active) !important;
} */
.navbar,
.website-header {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

#base-nav {
  overflow: hidden;
  background-color: #f1f1f1;
  padding: 40px 10px;
  /* Large padding which will shrink on scroll (using JS) */
  transition: 0.4s;
  /* Adds a transition effect when the padding is decreased */
}

.base-nav .nav-item .nav-link {
  font-size: 14px;
}

.base-nav .nav-item .nav-link span:hover {
  color: var(--bs-tertiary);
}

.accordion-button {
  text-transform: none !important;
  /* The !important is often needed to override existing styles */
}

.logo-container {
  height: 40px;
  overflow: hidden;
}

.base-logo {
  line-height: 30px;
  height: 50px;
  overflow: hidden;
}

.hotel-facilities-imgs img {
  height: 200px;
  width: 100%;
  transition: transform 0.2s;
}

.amenities-container {
  max-width: 750px;
  margin: auto;
}

.verified-tagline {
  background-color: #19875414;
  border-radius: 4px;
  padding: 2px 8px;
}

.amenity-section {
  background-color: #044e75;
  color: white;
  padding: 30px 0px;
}

.top-curve-img {
  left: 0;
  right: 0;
  top: 7%;
}

.north-east-icon {
  font-size: 16px;
  margin-top: 7px;
}

.main-bg {
  background: #3F51B5;
}

.see-all-imgs {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
}

.carousel-hero {
  width: 100%;
  height: calc(100vh - 215px);
  overflow: hidden;
  /* To prevent any image overflow */
}

.attraction-img-container {
  text-align: center;
}


.attraction-img-container img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.detail-box {
  min-height: 120px;
}

.facility-image-container,
.atttraction-image-container {
  overflow: hidden;
  border-radius: 0.5rem;
  height: 200px;
}

.gallary-image-container {
  overflow: hidden;
  border-radius: 0.5rem;
  height: 325px;
}

.facility-image-container img,
.gallary-image-container img,
.atttraction-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.social-desktop {
  display: flex;
}

.btn-half {
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.2) 0%, #FFFFFF 100%) !important;
  color: #D1704A;
}
.beverage-card .card-title{
  font-size: 18px;
  font-weight: 500;
}
.card-subtitle{
  font-size: 10px;
  color: rgba(153, 153, 153, 1);

}
.breakfast-menu{
  color: rgba(223, 140, 76, 1);
  font-family: "Pacifico", cursive;
  
}
.breakfast-tearms{
  background: rgba(243, 243, 243, 1);
  max-width: fit-content;
  /* margin: 0 auto; */
  border-radius: 8px;
}
.breakfast-header{
  justify-content: space-between;
}
.drinks{
  background: rgba(255, 247, 235, 1);
}
.drinks .card-title{
  color: rgba(223, 140, 76, 1);

}
.bowl{
  background: rgba(247, 250, 255, 1);

}
.bowl .card-title{
  color: rgba(67, 136, 247, 1);
}
.plate{
  background: rgba(249, 246, 255, 1);
}
.plate .card-title{
  color: rgba(131, 86, 222, 1);

}
.breakfast-img{
 display: none;
}
.toast-selection{
  padding-left: 12px;
}
@media (max-width: 768px) {
  .main {
    height: 100%;
  }

  .hero-heading-bg {
    background-size: contain;
    margin-top: 24px;
  }

  .hero-heading {
    margin-top: 150px;
  }

  /* .btn {
    padding: 0;
  } */
  .main h1 {
    font-size: 32px;
  }

  .main ul li {
    font-size: 14px;
  }

  .facility-part {
    padding: 0;
  }

  .facility-part p,
  .attraction-container p,
  .attraction-container h6,
  .attraction-container span {
    font-size: 16px;
  }
  .breakfast-header{
    justify-content: center;
  }
  .breakfast-img{
    display: block;
    width: 100% !important;
  }
  .facility-part h3,
  .main h3,
  .attraction-container h5 {
    font-size: 18px;
  }

  #tagline {
    font-size: 20px;
    line-height: 0;
  }

  .facility-count {
    font-size: 14px;
  }

  .social-desktop {
    display: none;
  }

  .map-row {
    margin-bottom: 20px;
  }

  .map {
    margin: 25px 0;
  }

  .image-container {
    height: 370px;
  }

  .social-responsive {
    display: block;
  }

  .social-responsive {
    display: block;
  }

  .core-pillars {
    margin: 0;
  }
}

@media (max-width: 576px) {
  .image-container {
    height: 260px;
  }
}

.card-title a:hover {
  text-decoration: underline;
}

.service-content-main {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.service-content {
  font-family: Roboto, Helvetica Neue, sans-serif;
}

.service-content .lead {
  font-size: 14px;
}

.service-content ul li {
  font-family: Roboto, Helvetica Neue, sans-serif;
  list-style-type: disc;
  font-size: 14px;
}

.service-content h3,
.service-content ul li {
  color: #000000de;
}

.base-button {
  margin-top: 20px;
  background-color: #3f51b5;
  color: #fff;
  border: none;
  height: 50px;
  max-width: 150px;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.base-li li a {
  font-size: 14px;
}

.base-about {
  background-color: #212121;
  box-sizing: border-box;
  color: #fff;
  padding: 20px;
}

.base-about p {
  line-height: 1.5;
  font-size: 14px;
  font-family: Archivo, Helvetica Neue, sans-serif;
  text-align: justify;
}

.contact-desktop {
  display: flex;
}

.img-card {
  margin-bottom: 0;
}

.company-logo {
  max-height: 80px;
  object-fit: contain;
  padding: 5px;
}

.operation {
  max-width: 33%;
  border-radius: 12px;
}

.plans-box {
  /* background: linear-gradient(344.47deg, #F4F7FF 19.69%, #FCF7FF 91.25%); */
  /* background: radial-gradient(circle,  #c497dd 20%, #7393eb 30%, #d2d6df 30%); */
  background:
    url('/public/images/kite.png') top left no-repeat,
    /* Image at the top left */
    linear-gradient(344.47deg, #F4F7FF 19.69%, #FCF7FF 91.25%);
  background-size: auto;
  height: auto;

}

.accordion-button:focus,
.accordion-button:active {
  box-shadow: none !important;
}

.card-middle a:hover {
  color: #D1704A;
}

.feature-bx {
  margin-top: 60px;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.box {
  max-width: 300px;
}

.bg-lenier {
  background: linear-gradient(360deg, rgba(179, 198, 255, 0.1) 0%, rgba(63, 81, 181, 0) 88.19%) !important;
  backdrop-filter: blur(4px)
    /* border: none; */
}

.text-white-opacity-50 {
  color: rgba(255, 255, 255, 0.5);
  /* #FFFFFF80 equivalent */
}

.btn-squere {
  border-radius: 8px;
}

.anthony-img {

  max-width: 155px;
  bottom: -110px;
  left: 150px;
  filter: grayscale(100%);
}

.bg-gray {
  background: linear-gradient(to bottom, rgba(63, 81, 181, 0.1) 60%, white 40%);
}

.contact-section {
  margin: 0 70px;
}

*/ .table-striped.bg-pink {
  --bs-table-striped-bg: white;
  /* --bs-table-bg: rgba(209, 112, 74, 0.08); */
  --bs-table-bg: rgba(235, 142, 111, 0.08);
}

.card-side {
  background: rgba(235, 142, 111, 0.1);

}

.card-side .title {
  color: #D1704A;
}

.img-card,
.testimonial-main,
.review-card {
  height: 470px;
}

.img-card,
.testimonial-main,
.review-card {
  height: auto;
}

#remove-shadow {
  box-shadow: none !important;
}

.card-plan {
  max-width: 320px;
  border-radius: 12px;
}

.talk {
  max-width: 100%;
}

.card-middle a {
  color: #D1704A;
}

.card-middle {
  background: rgba(209, 112, 74, 1) url('/public/images/silver_bg.png') no-repeat left top;
  background-size: 71px 97px;
  /* Resize the image if needed */
}

.footer-link:hover {
  color: #001C68;
}

.card-base {
  background: rgba(235, 142, 111, 0.1) url('/public/images/base_bg.png') no-repeat left top;
  background-size: 71px 97px;
  /* Resize the image if needed */
}

.card-diamond {
  background: rgba(235, 142, 111, 0.1) url('/public/images/diamond_bg.png') no-repeat left top;
  background-size: 71px 97px;
  /* Resize the image if needed */
}

.review-arrow {
  padding-inline: 35px;
  bottom: -80px;
  left: 45%;
}

/* .sign-in-btn {
  margin-left: 30px;
} */

.btn-tertiary {
  color: #fff;
}

.swal2-styled {
  background: #142271;
  color: white;

}

.mw-40 {
  max-width: 40ch;
}

.mw-60 {
  max-width: 60ch;
}

.btn-tertiary:hover {
  background: rgba(255, 255, 255, 1);
  /* color: var(--bs-tertiary); */
  color: #3F51B5;
}

.btn-tfooter:hover {
  background: #142271;
  color: white;

}

.form-control {
  border-radius: 0;
}

.form-control:focus {
  box-shadow: none;
  /* Removes the glowing shadow */
  border-color: transparent;
  /* Removes the black border */
}

.index-header .active {

  text-decoration: underline;
  text-underline-offset: 4px;

}

.btn-tertiary {
  color: #fff;
  /* background: #001C68; */
}

.btn-white {
  background: #FFFFFF;
  opacity: 1 !important;
  /* color: var(--bs-tertiary); */
  color: #3F51B5;

}

.btn-white:hover {
  color: #fff;
  /* border: none; */
  /* background: #001C68; */
  border: 1px solid #3F51B5;
  /* border: none; */
  /* background: rgba(63, 81, 181,1); */
  background: #3F51B5;
}

.btn-hover:hover {
  background: #001C68;
  border: 1px solid #001C68;
}

/* .cust-num-bx div{
  width: 14%;
} */
#hotel-img {
  width: 640px;
  height: 360px;
  border-radius: 12px;
}

.rect-shape {
  overflow: hidden;
  padding: 75px 20px 0 20px;
}

.rect-shape::before {
  content: '';
  display: block;
  position: absolute;
  left: -27px;
  top: 0;
  width: calc(100vw + 40px);
  border: solid #ff000000;
  border-width: 150px;
  border-top-color: #3F51B5;
}

.footer-talk {
  top: -10px;
  border-radius: 20px;
}


.client-img {
  margin: 50px 100px;
}

.radius-16 {
  border-radius: 16px;
}

.radius-6 {
  border-radius: 6px;
}

.table-responsive {
  overflow-x: auto;
  /* Ensures horizontal scrolling */
}

.table-price {
  min-width: 600px;
  /* Set a minimum width for the table */
}

.radius-12 {
  border-radius: 12px;
}

#reviewer-img {
  border-radius: 15px;
}

.abt-imgs {
  height: 502px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.abt-img {
  width: 24%;
  transition: transform 0.8s ease-out, opacity 0.8s ease-out;
  opacity: 0;
}

/* Odd images move from bottom to top */
.abt-img:nth-child(odd) {
  align-self: flex-end;
  transform: translateY(30px);
}

/* Even images move from top to bottom */
.abt-img:nth-child(even) {
  align-self: flex-start;
  transform: translateY(-30px);
}

/* Show effect */
.abt-img.show {
  transform: translateY(0);
  opacity: 1;
}

.table {
  --bs-table-accent-bg: #EB8E6F14;
}

.table-striped tbody tr:nth-of-type(odd) {
  --bs-table-striped-bg: white;
  /* Or #ffffff */
}

.navbar {
  --bs-navbar-toggler-border-color: none;
  --bs-navbar-toggler-focus-width: 0;
}

.dashboard-img {
  background-image: url(/public/images/index_dashboard.png);
  background-repeat: no-repeat;
  width: 100%;
  padding-bottom: 40%;
  background-position: center;
  background-size: cover;
}

@media (max-width: 1200px) {
  .card-plan {
    max-width: 30%;
    border-radius: 12px;
  }
}

.grid-container {
  position: relative;
  background: linear-gradient(rgba(45, 70, 185, 0) 5%,
      rgba(45, 70, 185, 0) 95%);
  overflow: hidden;
}

.footer-row {
  background-color: white;
  border-radius: 20px;
}

.grid-container::before {
  pointer-events: none;
  content: '';
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 10%;
  background-size: 80px 80px;
  background-image:
    linear-gradient(to right, rgba(255, 255, 255, 0.15) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 1px, transparent 1px);
  opacity: 0.8;

  /* Mask for top and bottom fade (creating the margin) - Applied FIRST */
  -webkit-mask-image: linear-gradient(to bottom, black 10%, transparent 90%),
    linear-gradient(to top, black 10%, transparent 90%);
  mask-image: linear-gradient(to bottom, black 10%, transparent 90%),
    linear-gradient(to top, black 10%, transparent 90%);

  /* Mask for LEFT-to-RIGHT fade (50%) - Applied SECOND */
  -webkit-mask-composite: intersect;
  mask-composite: intersect;
  -webkit-mask-image: linear-gradient(to RIGHT, black 50%, transparent 100%);
  /* Corrected: to RIGHT */
  mask-image: linear-gradient(to RIGHT, black 50%, transparent 100%);
  /* Corrected: to RIGHT */
}

@media (max-width: 768px) {
  .cust-num-bx h3 {
    font-size: 45px;
  }

  .dashboard-img {
    background-image: url(/public/images/index_dashboard_mobile.png);
    background-repeat: no-repeat;
    width: 100%;
    padding-bottom: 111%;
    background-position: center;
    background-size: cover;
  }

  .pricing-section {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .client-img {
    margin: 50px 70px;
  }

  .feature-bx {
    border: none;
    border-radius: 0;
    padding-left: 8px;
    padding-right: 8px;
  }

  /* .cust-num-bx div{
    width: auto;
  } */
  .card-plan {
    max-width: 90%;
    border-radius: 12px;
  }

  .rect-shape {
    padding: 75px 20px 0 20px;
  }

  .card-middle {
    margin: 10px 0;
  }

  .operation {
    max-width: 100%;
  }

  .abt-imgs {
    display: flex;
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .abt-img {
    width: 80%;
    transition: transform 0.3s ease-out, opacity 0.8s ease-out;
    /* Shortened transition */
    opacity: 0;
  }

  .abt-img:nth-child(odd) {
    padding-left: 15%;
    transform: translateX(-30px);
    /* Initial position slightly further left */
  }

  .abt-img:nth-child(even) {
    padding-right: 15%;
    transform: translateX(30px);
    /* Initial position slightly further right */
  }

  .abt-img.show {
    transform: translateX(0);
    /* Animate to original padding position */
    opacity: 1;
  }

  .footer-talk {
    top: -40px;
    border-radius: 20px;
    padding: 0;
  }

  .review-card {
    margin-top: 70px;
  }

  .review-arrow {
    bottom: -80px;
    left: 0;
    width: 100%;
    justify-content: space-between;
    margin: auto;

  }

  .testimonials-btn {
    margin-bottom: 20px;
  }

  .benifits {
    padding: 0 30px;
  }

  .sign-in-btn {
    margin: 10px 0;
  }

  .img-card {
    margin-bottom: 45px;
  }

  .msg-frm {
    margin-top: 20px;
  }

  .contact-section {
    gap: 40px;
    flex-direction: column-reverse;
    margin: 0px 10px;
    /* margin-top: -350px; */
  }

  .talk {
    max-width: 100%;
    margin-bottom: 10px;
    padding: 20px 10px;
  }

  .contact-desktop {
    display: none !important;
  }

  .section {
    margin-bottom: 60px !important;
  }

  .title-part {
    margin-bottom: 20px !important;
  }

  footer {
    margin-top: 90px;
  }

  .header-main-above a {
    font-size: 12px;
  }

  .check-in {
    margin: 0 auto;
  }

  .btn a {
    font-size: 14px;
  }

  .h2 {
    font-size: 40px;
  }

  .base-button {
    max-width: 100%;
    margin-bottom: 20px;
    justify-content: center;
  }
}

.beverage-card {
  margin: 20px 0;
}

.beverage-card #toastSelectionYolk , .beverage-card #toastSelectionOmlet ,  .beverage-card #toastSelectionWhiteOnly {
  display: none;
  background-color: #EEE8FB;
  padding: 6px;
  border-radius: 10px;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: transparent;
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.radio-group label {
  flex: 1 1 45%;
}

.checkbox-group-4 {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-group-4 label {
  flex: 1 1 20%;
  box-sizing: border-box;
  padding: 5px;
}

.checkbox-group-2 {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-group-2 label {
  flex: 1 1 40%;
  box-sizing: border-box;
  padding: 5px;
}